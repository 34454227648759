<template>

	<div class="widget-min-box" :widget-id="widget.id" :widget-type="widget.type">
		<slot v-if="widgetLoad">
			<div class="widget-min-box-content text-center p-2">
				<div class="widget-loading"></div>
			</div>
		</slot>
		<slot v-else>
			<div class="widget-min-box-list" :class="{'min-show': responsive.rightSection}">

				<slot v-if="widgetLoad">
					<div class="text-center p-2">
						<div class="widget-loading"></div>
					</div> 
				</slot>
				<slot v-else>
					<div class="list-widget list-dash-items list-widget-structure" data-list-type="favorite-cat">
						<div class="list-row l-head">
							<div class="list-col l-item">
								<span class="width-fix">ترتیب</span>
							</div>
							<div class="list-col l-title">
								<span>فهرست علاقه مندی ها</span>
							</div>
							<div class="list-col l-item"><span class="width-fix">ویرایش</span></div>
							<div class="list-col l-item"><span class="width-fix">حذف</span></div>
						</div>

						<draggable v-model="favorites" group="favorites" @start="drag=true" @end="saveFavoritesSort()" ghost-class="drag-ghost" v-if="favorites">
							<template v-for="favorite in favorites">
								<div :key="favorite.id" class="list-row item-draggable hover bold">
									<div class="list-col l-item pointer">
										<i class="uil uil-arrows-v list-drag"></i>
									</div>
									<div class="list-col l-title l-title-widget-structure" @click="openFavorite(favorite.id); makeResponsive(2)">
										<span>{{ favorite.title }}</span>
									</div>
									<div class="list-col l-item pointer"  @click="open_favorite_edit_modal(favorite.id)">
										<i class="uil-edit-alt"></i>
									</div>
									<div class="list-col l-item red-color pointer" @click="removeFavorite(favorite.id)">
										<i class="uil uil-trash remove-row"></i>
									</div>
								</div>
							</template>
						</draggable>
					</div>
					<div :class="'list-widget-add-container ' + (addEditLoading ? 'loading-active' : '') ">
						<i class="fa fa-plus list-widget-add-icon" aria-hidden="true" @click="addFavorite()"></i>
						<input class="list-widget-input-add" type="text" placeholder="افزودن لیست جدید" v-model="newFavorite" @keyup.enter="addFavorite()">
					</div>
				</slot>

			</div>

			<div class="widget-min-box-content" :class="{'min-show': responsive.leftSection}">
				<slot v-if="widgetLoad">
					<div class="text-center p-2">
						<div class="widget-loading"></div>
					</div>
				</slot>
				<slot v-else>
					<div class="list-row l-head">
						<div class="list-col l-item">
							<span class="fa fa-arrow-right widget-min-back-to-list" @click="makeResponsive(1)"></span>
						</div>
						<div class="list-col l-title">
							<span v-if="activeFavorite">مشاهده لیست :  {{ activeFavorite.title }}</span>
						</div>
						<div v-if="activeFavorite" class="list-col l-item pointer hover head-color">
							<span class="btn btn-primary btn-sm list-col-left-btn" @click="open_favorite_add_modal()">
								<div class="fa fa-plus l-item-btn-pages"></div>
								افزودن لینک
							</span>
						</div>
					</div>

					<div class="tgju-widget-content full-padding">


						<slot v-if="!activeFavorite || activeFavorite.links.length == 0">
							<div class="popup-dash-empty">
								<i class="uil uil-exclamation" aria-hidden="true"></i>
								<span>مقداری برای نمایش وجود ندارد</span>
							</div>
						</slot>
						<slot v-else>
							<slot v-if="activeFavorite">
								<div class="list-widget list-dash-items list-widget-structure">
									<div class="list-row l-head">
										<div class="list-col l-item">
											<span class="width-fix">ترتیب</span>
										</div>
										<div class="list-col l-title">
											<span>عنوان</span>
										</div>
										<div class="list-col l-item"><span class="width-fix">ویرایش</span></div>
										<div class="list-col l-item"><span class="width-fix">حذف</span></div>
									</div>

									<draggable v-model="activeFavorite.links" group="favoriteslinks" @start="drag=true" @end="saveFavoriteLinkSort()" ghost-class="drag-ghost">
										<template v-for="link in activeFavorite.links">
											<div :key="link.id" class="list-row item-draggable hover bold">
												<div class="list-col l-item pointer">
													<i class="uil uil-arrows-v list-drag"></i>
												</div>
												<div class="list-col l-title l-title-widget-structure" @click="openLink(link.id)">
													<span>{{ link.title }}</span>
												</div>
												<div class="list-col l-item blue-color pointer" @click="open_favorite_add_modal(link.id)">
													<i class="uil-edit-alt"></i>
												</div>
												<div class="list-col l-item red-color pointer" @click="removeFavoriteLink(link.id)">
													<i class="uil uil-trash remove-row"></i>
												</div>
											</div>
										</template>
									</draggable>
								</div>
							</slot>
							<slot v-else>
								<div class="text-center p-2">
									<div class="widget-loading"></div>
								</div>
							</slot>
						</slot>
				
					</div>
				</slot>
			</div>
		</slot>
	</div>

</template>

<style lang="scss">
	.widget-min-box-content {
		.l-title {
			display: flex;
		}

		.edit-favorite {
			font-size: 14px;
			cursor: pointer;
			margin-right: -3px;
		}
	}
</style>

<script>
	import draggable from 'vuedraggable'

	export default {
		name: 'Favorite',
		props: ['widget', 'data', 'editable', 'wrapper'],
		components: {
			draggable
		},
		data() {
			return {
				favorites: [],
				activeFavorite: undefined,
				newFavorite: '',
				responsive: {
					rightSection: true,
					leftSection: false,
					leftPanel: false,
				},
				widgetLoad: true,
				addEditLoading: false,
			}
		},

		mounted() {
			this.$watch('data', function(newValue, oldValue) {
				if ( Object.keys(newValue).length && ( typeof oldValue == 'undefined' || !Object.keys(oldValue).length ) ) {
					this.loadData();
				}
			}, {immediate: true});
		},

		methods: {		
			loadData() {
				this.favorites = this.data.favorites;

				if ( this.favorites.length ) {
					this.activeFavorite = this.favorites[0];
				}

				this.widgetLoad = false;
			},

			removeFavorite(id) {

				this.$helpers.confirm('هشدار حذف!', {
					message: 'آیا از حذف این مورد اطمینان دارید؟',
				}).then((confirmed) => {
					if ( confirmed ) {
						
						this.activeFavorite = undefined;

						let index = this.$helpers.getIndex(this.favorites, id);

						this.favorites.splice(index, 1);
						
						let next_index = index - 1;
						if ( next_index < 0 ) 
							next_index = 0;

						if(this.favorites.length > 0) {
							this.openFavorite(this.favorites[next_index].id);
						}

						this.$helpers.makeRequest('DELETE', '/bookmark/delete/' + id).then(api_response => {
							this.$helpers.api_alert(api_response);

							if ( api_response.status == 200 ) {
								this.$swal.fire({
									icon:"success",
									title:"فهرست با موفقیت حذف شد",
									showConfirmButton:!1,
									timer:2000
								});
								this.showSidebar();
							}else{
								this.$swal.fire({
									icon:"error",
									title:"مشکلی در حذف فهرست رخ داده است",
									showConfirmButton:!1,
									timer:2000
								});
							}
						});	

					}
				});
			},

			openFavorite(id) {
				let index = this.$helpers.getIndex(this.favorites, id);

				this.activeFavorite = this.favorites[index];
			},

			addFavorite() {
				if ( !this.newFavorite ) return;
				this.addEditLoading = true;

				let id = 'id-' + new Date().getTime();

				this.favorites.push({
					id,
					title: this.newFavorite,
					links: []
				});

				let api_data = {
					title: this.newFavorite,
					links: null,
				};

				this.$helpers.makeRequest('POST', '/bookmark/save', api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						let inserted_id = api_response.data.response.id;

						this.favorites.forEach((favorite, index) => {
							if ( favorite.id == id ) {
								favorite.id = inserted_id;
							}
						});
						this.$swal.fire({
							icon:"success",
							title:"فهرست با موفقیت ایجاد شد",
							showConfirmButton:!1,
							timer:2000
						});
						this.addEditLoading = false;
					}else{
						this.$swal.fire({
							icon:"error",
							title:"مشکلی در ایجاد فهرست رخ داده است",
							showConfirmButton:!1,
							timer:2000
						});
						this.addEditLoading = false
					}
				});

				this.newFavorite = '';
				this.openFavorite(id);
			},

			saveFavoritesSort() {
				let sort = [];
				this.favorites.forEach(favorite => {
					sort.push(favorite.id);
				});

				let api_data = {
					sort: JSON.stringify(sort),
				};

				this.$helpers.makeRequest('POST', '/bookmark/sort', api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						Vue.prototype.$toast.success({
							title : 'ذخیره',
							message : 'مرتب سازی با موفقیت ذخیره شد',
						});
					}else{
						Vue.prototype.$toast.warn({
							title : 'ذخیره',
							message : 'مشکلی در مرتب سازی رخ داده است',
						});
					}
				});
			},

			saveFavoriteLinkSort() {
				this.saveFavoriteLink();
			},

			removeFavoriteLink(id) {
				let index = this.$helpers.getIndex(this.activeFavorite.links, id);

				this.activeFavorite.links.splice(index, 1);

				this.saveFavoriteLink('delete');
			},

			saveFavoriteLink(action = null) {
				let api_data = {
					links: JSON.stringify(this.$helpers.unbindObject(this.activeFavorite.links)),
				};

				this.$helpers.makeRequest('POST', '/bookmark/link-save/' + this.activeFavorite.id, api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						if( action == 'editLink' ) {
							this.$swal.fire({
								icon:"success",
								title:"لینک با موفقیت ویرایش شد",
								showConfirmButton:!1,
								timer:2000
							});
						} else if( action == 'addLink' ) {
							this.$swal.fire({
								icon:"success",
								title:"لینک با موفقیت ایجاد شد",
								showConfirmButton:!1,
								timer:2000
							});
						} else if( action == 'delete' ) {
							this.$swal.fire({
								icon:"success",
								title:"لینک با موفقیت حذف شد",
								showConfirmButton:!1,
								timer:2000
							});
						}
					} else {
						if( action == 'editLink' ) {
							this.$swal.fire({
								icon:"error",
								title:"مشکلی در ویرایش لینک رخ داده است",
								showConfirmButton:!1,
								timer:2000
							});
						} else if( action == 'addLink' ) {
							this.$swal.fire({
								icon:"error",
								title:"مشکلی در ایجاد لینک رخ داده است",
								showConfirmButton:!1,
								timer:2000
							});
						} else if( action == 'delete' ) {
							this.$swal.fire({
								icon:"error",
								title:"مشکلی در حذف لینک رخ داده است",
								showConfirmButton:!1,
								timer:2000
							});
						}
					}
				});
			},

			openLink(id) {
				let index = this.$helpers.getIndex(this.activeFavorite.links, id);

				window.open(this.activeFavorite.links[index].url);
			},

			open_settings_modal() {
				let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

				let modal_data = {
					modal: 'widget_settings_modal',
					callbackEvent,
					component: 'Settings/Favorite.vue',
					config: {
						data: {
							title: this.widget.title,
						}
					}
				};

				modal_data = this.$helpers.unbindObject(modal_data);

				this.$root.$emit('open_modal', modal_data);

				this.$root.$on(callbackEvent, (settings) => {
					this.widget.title = settings.title;

					let widget = this.$helpers.unbindObject(this.widget);

					this.$helpers.saveWidget(widget);
				});
			},
			
			open_favorite_add_modal(id = null) {
				let callbackEvent = this.$router.currentRoute.name + '_AddLinks_' + new Date().getTime();

				let action = ( id ) ? 'edit' : 'add';
				let link = null;

				if ( id ) {
					let index = this.$helpers.getIndex(this.activeFavorite.links, id);
					link = this.activeFavorite.links[index];
				}

				let modal_data = {
					modal: 'general_modal',
					callbackEvent,
					component: 'FavoriteAdd.vue',
					config: {
						title:( action == 'edit' ) ? 'ویرایش لینک' : 'افزودن لینک',
						icon: ( action == 'edit' ) ? 'uil uil-edit-alt with-icon' : 'uil uil-plus-circle with-icon',
						smallModal: true,
						data: {
							action,
							link,
						},
					}
				};

				this.$root.$emit('open_modal', modal_data);

				this.$root.$on(callbackEvent, (response) => {
					if ( response.action == 'addLink' ) {
						this.activeFavorite.links.push({
							id: 'id-' + new Date().getTime(),
							title: response.data.link.title,
							url: response.data.link.url,
						});

						this.saveFavoriteLink('addLink');
					} else if ( response.action == 'editLink' ) {
						link.title = response.data.link.title;
						link.url = response.data.link.url;

						this.saveFavoriteLink('editLink');
					}
				});
			},
			
			open_favorite_edit_modal(id = 0) {

				if (id > 0) {
					let index = this.$helpers.getIndex(this.favorites, id);
					this.activeFavorite = this.favorites[index];
				}

				let callbackEvent = this.$router.currentRoute.name + '_EditFavorites_' + new Date().getTime();

				let modal_data = {
					modal: 'general_modal',
					callbackEvent,
					component: 'FavoriteEdit.vue',
					config: {
						title: 'ویرایش علاقه‌مندی',
						icon: 'uil uil-edit-alt with-icon',
						smallModal: true,
						data: {
							favorite: this.$helpers.unbindObject(this.activeFavorite),
						},
					}
				};

				this.$root.$emit('open_modal', modal_data);

				this.$root.$on(callbackEvent, (response) => {
					if ( response.action == 'editFavorite' ) {
						this.activeFavorite.title = response.data.favorite.title;

						let api_data = {
							title: this.activeFavorite.title,
						};

						this.$helpers.makeRequest('POST', '/bookmark/edit/' + this.activeFavorite.id, api_data).then(api_response => {
							this.$helpers.api_alert(api_response);

							if ( api_response.status == 200 ) {
								this.$swal.fire({
									icon:"success",
									title:"فهرست با موفقیت ویرایش شد",
									showConfirmButton:!1,
									timer:2000
								});
							}else{
								this.$swal.fire({
									icon:"error",
									title:"مشکلی در ویرایش فهرست رخ داده است",
									showConfirmButton:!1,
									timer:2000
								});
							}
						});
					}
				});
			},

			makeResponsive(mode) {
				if ( mode == 1 ) {
					this.responsive.rightSection = true;
					this.responsive.leftSection = false;
				} else if ( mode == 2 ) {
					this.responsive.rightSection = false;
					this.responsive.leftSection = true;
				}
			}
		}
	}
</script>